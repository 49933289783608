import Link from 'next/link';
import styled from 'styled-components';
// components
import Icon from 'cds/icons';
// slices
import { readNotification, removeNotification } from 'slices/user.thunk';
// hooks
import { useDispatch, useSelector } from 'hooks/common/useStore';
// styles
import { palette, onlyHover } from 'cds/styles';
import { useEffect, useState } from 'react';
import deviceChecker from 'lib/utils/deviceChecker';

/**
 * 단일 알림
 * @param {object} props
 * @param {import('types/slices/user').Notify} props.noti 알림 정보
 */
const Notification = ({ noti }) => {
  const dispatch = useDispatch();
  const myId = useSelector(state => state.user.info.id);

  const [isApp, setIsApp] = useState(false);

  /** 아이콘 타입 */
  const iconType = {
    1: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/img/class/notification/ic_noti_attend.png`,
    2: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/img/class/notification/ic_noti_add_friend.png`,
    3: `${process.env.NEXT_PUBLIC_ASSET_PREFIX}/img/class/notification/ic_noti_alarm.png`,
  };

  /** 알림 타입 */
  const pushType = {
    0: `${noti.push_url}`,
    1: `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/lecture/${noti.mcClass_id}`,
    2: `/community/${noti.mcComment_id}`,
    3: `/community/${noti.mcComment_id}`,
    4: `/user/${noti.friend_id}`,
    5: `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/`,
    6: `/user/${myId}`,
    7: ``,
    8: `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/chat/${noti.friend_id}`,
    9: ``,
    10: `${process.env.NEXT_PUBLIC_DEFAULT_HOST}/class/lecture/${noti.mcClass_id}`,
    11: `${noti.push_url}`,
    12: `${noti.push_url}`,
    13: `${noti.push_url}`,
    14: `${noti.push_url}`,
  };

  const onRead = () => {
    dispatch(readNotification({ notifyId: noti.id, readStatus: noti.read_status }));
  };

  const onRemove = e => {
    e.stopPropagation();
    dispatch(removeNotification({ notifyId: noti.id, readStatus: noti.read_status }));
  };

  useEffect(() => {
    const device = deviceChecker();
    if (device.app()) {
      return setIsApp(true);
    }

    setIsApp(false);
  }, []);

  const isExternal = noti.push_url?.includes('webview/external');
  const hasRedirectURL = isExternal && noti.push_url.includes('?');
  const redirectURL = hasRedirectURL && noti.push_url.split('?')[1].replace('redirect=', '');

  return (
    <Layout isRead={noti.read_status === 'Y'} onClick={onRead}>
      <Link href={`/user/${noti.friend_id}`} passHref>
        <Profile>
          <Photo url={noti.photo} />
          <Badge url={iconType[noti.icon_type]} />
        </Profile>
      </Link>

      {isExternal ? (
        hasRedirectURL ? (
          <Link href={redirectURL} passHref>
            <Content target="_blank">
              <Title>{noti.body}</Title>
              <Name>{noti.short_name}</Name>
              <Time>{noti.time_spilled}</Time>
            </Content>
          </Link>
        ) : (
          <Link href={noti.push_type < 15 ? pushType[noti.push_type] : noti.push_url} passHref>
            <Content target="_blank">
              <Title>{noti.body}</Title>
              <Name>{noti.short_name}</Name>
              <Time>{noti.time_spilled}</Time>
            </Content>
          </Link>
        )
      ) : (
        <Link href={noti.push_type < 15 ? pushType[noti.push_type] : noti.push_url} passHref>
          <Content>
            <Title>{noti.body}</Title>
            <Name>{noti.short_name}</Name>
            <Time>{noti.time_spilled}</Time>
          </Content>
        </Link>
      )}
      <CloseButton onClick={onRemove}>
        <Icon name="ic_close_m" width="16" height="16" fill={palette.grey25} />
      </CloseButton>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 12px;
  background-color: ${({ isRead }) => (isRead ? '' : palette.BTN.BG)};
  cursor: pointer;
  border-bottom: 1px solid ${palette.BTN.BG};
`;

const Profile = styled.a`
  position: relative;
`;

const Photo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};
`;

const Badge = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
  background-image: ${({ url }) => `url(${url})`};
`;

const Content = styled.a`
  display: block;
  flex: 1;
  width: auto;
  padding: 8px 0;
  margin-left: 12px;
  line-height: 12px;
`;

const Title = styled.strong`
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${palette.grey50};
`;

const Name = styled.span`
  font-size: 10px;
  color: ${palette.grey25};
`;

const Time = styled.span`
  font-size: 10px;
  color: ${palette.font.tertiary2};

  &::before {
    content: '\\B7';
    display: inline-block;
    margin: 0 4px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  opacity: 0.4;

  ${onlyHover} {
    &:hover {
      opacity: 1;
    }
  }

  &:active {
    opacity: 1;
  }
`;

export default Notification;
